
<template>
  <div>
    <div class="vx-row">
      <div class="vx-col w-1/2 md:w-1/2 lg:w-1/4 mb-base">
          <statistics-card-line
            v-if="registeredBuyers.analyticsData"
            icon="UsersIcon"
            :hideChart="true"
            :statistic="registeredBuyers.analyticsData.total"
            statisticTitle="Registered Buyers"
            type='area' />
      </div>
      <div class="vx-col w-1/2 md:w-1/2 lg:w-1/4 mb-base">
        <statistics-card-line
          v-if="orders.analyticsData"
          icon="DatabaseIcon"
          :hideChart="true"
          :statistic="orders.analyticsData.total"
          statisticTitle="Total Orders"
          color='danger'
          type='area' />
      </div>
      <div class="vx-col w-1/2 md:w-1/2 lg:w-1/4 mb-base">
        <statistics-card-line
          v-if="reviews.analyticsData"
          icon="ShoppingCartIcon"
          :hideChart="true"
          :statistic="orders.analyticsData.draft"
          statisticTitle="Saved Drafts"
          color='warning'
          type='area' />
      </div>
      <div class="vx-col w-1/2 md:w-1/2 lg:w-1/4 mb-base">
        <statistics-card-line
          v-if="reviews.analyticsData"
          icon="AwardIcon"
          :hideChart="true"
          :statistic="reviews.analyticsData.total"
          statisticTitle="Total Reviews"
          color='success'
          type='area' />
      </div>
    </div>

    <div class="vx-row mb-6" v-if="!isLoading">
      <div class="vx-col w-full lg:w-1/3">
        <vx-card title="Payment Statistics" class="h-full">
          <template slot="actions">
              <feather-icon icon="HelpCircleIcon" svgClasses="w-6 h-6 text-grey"></feather-icon>
          </template>

          <template slot="no-body">
              <div class="mt-10 mb-16">
                  <vue-apex-charts type=radialBar height=220 :options="analyticsData.goalOverviewRadialBar.chartOptions" :series="paymentOverview.series" />
              </div>
          </template>

          <div class="flex justify-between text-center" slot="no-body-bottom">
              <div class="w-1/2 border border-solid d-theme-border-grey-light border-r-0 border-b-0 border-l-0">
                  <p class="mt-4">Paid</p>
                  <p class="mb-4 text-3xl font-semibold">{{paymentOverview.analyticsData.paid.toLocaleString()}}</p>
              </div>
              <div class="w-1/2 border border-solid d-theme-border-grey-light border-r-0 border-b-0">
                  <p class="mt-4">Unpaid</p>
                  <p class="mb-4 text-3xl font-semibold">{{paymentOverview.analyticsData.unpaid.toLocaleString()}}</p>
              </div>
          </div>
        </vx-card>
      </div>

      <div class="vx-col w-full lg:w-1/3 lg:mt-0 mt-base">
        <vx-card title="System Users" class="system-user-chart">
          <template slot="actions">
              <change-time-duration-dropdown />
          </template>
          <div slot="no-body">

                <vue-apex-charts class="mt-8 mb-5" type=donut height=225 :options="analyticsData.systemUsersDonut.chartOptions" :series="systemUsersData.series" />

                <ul class="mt-1">
                    <li v-for="systemUserData in systemUsersData.analyticsData" :key="systemUserData.role" class="flex justify-between py-3 px-6 border d-theme-border-grey-light border-solid border-r-0 border-l-0 border-b-0">
                    <span>
                        <span class="inline-block h-3 w-3 rounded-full mr-2" :class="`bg-${systemUserData.color}`"></span>
                        <span class="inline-block font-semibold">{{ systemUserData.role }}</span>
                    </span>
                    <span>{{ systemUserData.counts }}</span>
                    </li>
                </ul>
          </div>
        </vx-card>
      </div>

      <div class="vx-col w-full lg:w-1/3 mt-base lg:mt-0">
        <vx-card title="Orders">
          <template slot="actions">
              <change-time-duration-dropdown />
          </template>

          <div slot="no-body">
              <vue-apex-charts type=pie height=180 class="mt-8 mb-5" :options="analyticsData.ordersDataPie.chartOptions" :series="ordersData.series" />

              <ul class="mb-1">
                  <li v-for="orderData in ordersData.analyticsData" :key="orderData.status" class="flex justify-between py-3 px-6 border d-theme-border-grey-light border-solid border-r-0 border-l-0 border-b-0">
                      <span class="flex items-center">
                          <span class="inline-block h-3 w-3 rounded-full mr-2" :class="`bg-${orderData.color}`"></span>
                          <span class="font-semibold">{{ orderData.status }}</span>
                      </span>
                      <span>{{ orderData.counts }}</span>
                  </li>
              </ul>
          </div>
        </vx-card>
      </div>
    </div>

    <div class="vx-row">
      <!-- LINE CHART -->
      <div class="vx-col w-full mb-base">
        <vx-card title="Revenue">
          <template slot="actions">
              <feather-icon icon="SettingsIcon" svgClasses="w-6 h-6 text-grey"></feather-icon>
          </template>
          <div slot="no-body" class="p-6 pb-0">
            <div class="flex" v-if="revenueComparisonLine.analyticsData">
              <div class="mr-6">
                <p class="mb-1 font-semibold">Total Revenue</p>
                <p class="text-3xl"><sup class="text-base mr-1">$</sup>{{ revenueComparisonLine.analyticsData.total.toLocaleString() }}</p>
              </div>
            </div>
            <vue-apex-charts
              type=line
              height=266
              :options="analyticsData.revenueComparisonLine.chartOptions"
              :series="revenueComparisonLine.series" />
          </div>
        </vx-card>
      </div>
    </div>
  </div>
</template>

<script>

import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import analyticsData from '@/plugins/settings/analyticsData.js'
import VueApexCharts from 'vue-apexcharts'
import StatisticsCardLine from '@/components/statistics-cards/StatisticsCardLine.vue'
import ChangeTimeDurationDropdown from '@/components/ChangeTimeDurationDropdown.vue'

export default{
    data() {
      return {
        isLoading: true,
        registeredBuyers: {
          analyticsData: {
            total: 0
          }
        },
        orders: {
          analyticsData: {
            total: 0,
            draft: 0
          }
        },
        reviews:  {
          analyticsData: {
            total: 0
          }
        },
        systemUsersData: {},
        ordersData: {},
        paymentOverview: {
          analyticsData: {
            paid: 0,
            unpaid: 0
          },
          series: [0]
        },
        analyticsData: analyticsData,
        revenueComparisonLine: {
          analyticsData: {
            total: 0,
          },
          series: [{
                  name: "Total Amount",
                  data: [45000, 47000, 44800, 47500, 45500, 48000, 46500, 48600]
              },
          ],
        },
        clientRetentionBar: {
          series: [
            {
              name: 'New Clients',
              data: [175, 125, 225, 175, 160, 189, 206, 134, 159, 216, 148, 123]
            },
            {
              name: 'Retained Clients',
              data: [-144, -155, -141, -167, -122, -143, -158, -107, -126, -131, -140, -137]
            }
          ],
        },
      }
    },
    components: {
      VueApexCharts,
      StatisticsCardLine,
      VuePerfectScrollbar,
      ChangeTimeDurationDropdown
    },
    methods: {
      parseOrdersData(orders){
        let obj = {
          analyticsData: [
            { status: 'Pending', counts: orders.pending, color: 'primary' },
            { status: 'Delivered', counts: orders.delivered, color: 'warning' },
            { status: 'Completed ', counts: orders.completed, color: 'success' },
            { status: 'Refunded ', counts: orders.refunded, color: 'danger' },
          ],
          series: [orders.pending, orders.delivered, orders.completed, orders.refunded],
        }
        this.ordersData = obj;
      },
      parseUsersData(users){
        let obj =  {
          analyticsData: [
            { role: 'Buyers', counts: users.buyer, color: 'success' },
            { role: 'Workers', counts: users.worker, color: 'primary' },
            { role: 'Admins ', counts: users.admin, color: 'danger' },
          ],
          series: [users.buyer, users.worker, users.admin]
        }
        this.systemUsersData = obj;
      },
      parsePaymentOverview(orders){
        this.paymentOverview =  {
          analyticsData: {
            paid: orders.paid,
            unpaid: orders.unpaid
          },
          series: [Math.round((orders.paid/(orders.paid + orders.unpaid))*100)]
        }
      }
    },
    created() {
     this.$http.get(`/analytics`)
      .then((response) => {
        if(response.data.success){
          var result = response.data.result
          this.registeredBuyers.analyticsData.total = result.users.buyer
          this.orders.analyticsData =  {...result.orders}
          this.reviews.analyticsData.total = result.reviews
          this.revenueComparisonLine.analyticsData.total = result.revenue
          this.parseOrdersData(result.orders);
          this.parsePaymentOverview(result.orders);
          this.parseUsersData(result.users);
          this.isLoading = false;
        }else{
          this.alertError(response.data.error)
        }

      }).catch((error) => { console.log(error) })
    }
}
</script>

<style lang="scss">
.chat-card-log {
    height: 400px;

    .chat-sent-msg {
        background-color: #f2f4f7 !important;
    }
}

.vx-card.system-user-chart .vx-card__body{
  padding-bottom:5px;
}
</style>
